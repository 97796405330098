// Font weights
export const FONT_WT_THIN = 100;
export const FONT_WT_LIGHT = 300;
export const FONT_WT_REGULAR = 400;
export const FONT_WT_MEDIUM = 500;
export const FONT_WT_BOLD = 500;
export const FONT_WT_HEAVY = 600;
export const FONT_WT_EXTRA_BOLD = 700;

// Font sizes
export const FONT_SIZE_26 = "26px";
export const FONT_SIZE_22 = "22px";
export const FONT_SIZE_18 = "18px";
export const FONT_SIZE_16 = "16px";
