// Screen Sizes
export const DESKTOP_L_BP = 1400;
export const DESKTOP_M_BP = 1200;
export const DESKTOP_SM_BP = 1024;
export const TABLET_M_BP = 990;
export const TABLET_BP = 768;
export const MOBILE_L_BP = 540;
export const MOBILE_LM_BP = 414;
export const MOBILE_ML_BP = 375;
export const MOBILE_M_BP = 360;
export const MOBILE_SM_BP = 322;
